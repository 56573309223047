import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _79fd5cf8 = () => interopDefault(import('../client/pages/kits/index.vue' /* webpackChunkName: "pages/kits/index" */))
const _72cc58c8 = () => interopDefault(import('../client/pages/lojas/index.vue' /* webpackChunkName: "pages/lojas/index" */))
const _73b37428 = () => interopDefault(import('../client/pages/mapa-do-site/index.vue' /* webpackChunkName: "pages/mapa-do-site/index" */))
const _b22eb608 = () => interopDefault(import('../client/pages/minha-conta/index.vue' /* webpackChunkName: "pages/minha-conta/index" */))
const _ed05e176 = () => interopDefault(import('../client/pages/motos/index.vue' /* webpackChunkName: "pages/motos/index" */))
const _5ce50e44 = () => interopDefault(import('../client/pages/reset/index.vue' /* webpackChunkName: "pages/reset/index" */))
const _14e626aa = () => interopDefault(import('../client/pages/veiculos/index.vue' /* webpackChunkName: "pages/veiculos/index" */))
const _1807777c = () => interopDefault(import('../client/pages/checkout/carrinho/index.vue' /* webpackChunkName: "pages/checkout/carrinho/index" */))
const _ee082024 = () => interopDefault(import('../client/pages/checkout/confirmacao/index.vue' /* webpackChunkName: "pages/checkout/confirmacao/index" */))
const _780155c4 = () => interopDefault(import('../client/pages/checkout/pagamento/index.vue' /* webpackChunkName: "pages/checkout/pagamento/index" */))
const _594b3877 = () => interopDefault(import('../client/pages/minha-conta/enderecos/index.vue' /* webpackChunkName: "pages/minha-conta/enderecos/index" */))
const _0d08d19d = () => interopDefault(import('../client/pages/minha-conta/meus-dados/index.vue' /* webpackChunkName: "pages/minha-conta/meus-dados/index" */))
const _2173c00a = () => interopDefault(import('../client/pages/minha-conta/pedidos/index.vue' /* webpackChunkName: "pages/minha-conta/pedidos/index" */))
const _e7843e3e = () => interopDefault(import('../client/pages/usuario/cadastro/index.vue' /* webpackChunkName: "pages/usuario/cadastro/index" */))
const _0da11197 = () => interopDefault(import('../client/pages/usuario/login/index.vue' /* webpackChunkName: "pages/usuario/login/index" */))
const _1964db3b = () => interopDefault(import('../client/pages/checkout/confirmacao/pix.vue' /* webpackChunkName: "pages/checkout/confirmacao/pix" */))
const _270c6d80 = () => interopDefault(import('../client/pages/produto/_id/_.vue' /* webpackChunkName: "pages/produto/_id/_" */))
const _c701f1c6 = () => interopDefault(import('../client/pages/kit/_id/_.vue' /* webpackChunkName: "pages/kit/_id/_" */))
const _a02829c2 = () => interopDefault(import('../client/pages/lista/_.vue' /* webpackChunkName: "pages/lista/_" */))
const _df078c34 = () => interopDefault(import('../client/pages/informacoes-gerais/_.vue' /* webpackChunkName: "pages/informacoes-gerais/_" */))
const _6bce249e = () => interopDefault(import('../client/pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/kits",
    component: _79fd5cf8,
    name: "kits"
  }, {
    path: "/lojas",
    component: _72cc58c8,
    name: "lojas"
  }, {
    path: "/mapa-do-site",
    component: _73b37428,
    name: "mapa-do-site"
  }, {
    path: "/minha-conta",
    component: _b22eb608,
    name: "minha-conta"
  }, {
    path: "/motos",
    component: _ed05e176,
    name: "motos"
  }, {
    path: "/reset",
    component: _5ce50e44,
    name: "reset"
  }, {
    path: "/veiculos",
    component: _14e626aa,
    name: "veiculos"
  }, {
    path: "/checkout/carrinho",
    component: _1807777c,
    name: "checkout-carrinho"
  }, {
    path: "/checkout/confirmacao",
    component: _ee082024,
    name: "checkout-confirmacao"
  }, {
    path: "/checkout/pagamento",
    component: _780155c4,
    name: "checkout-pagamento"
  }, {
    path: "/minha-conta/enderecos",
    component: _594b3877,
    name: "minha-conta-enderecos"
  }, {
    path: "/minha-conta/meus-dados",
    component: _0d08d19d,
    name: "minha-conta-meus-dados"
  }, {
    path: "/minha-conta/pedidos",
    component: _2173c00a,
    name: "minha-conta-pedidos"
  }, {
    path: "/usuario/cadastro",
    component: _e7843e3e,
    name: "usuario-cadastro"
  }, {
    path: "/usuario/login",
    component: _0da11197,
    name: "usuario-login"
  }, {
    path: "/checkout/confirmacao/pix",
    component: _1964db3b,
    name: "checkout-confirmacao-pix"
  }, {
    path: "/produto/:id?/*",
    component: _270c6d80,
    name: "produto-id-all"
  }, {
    path: "/kit/:id?/*",
    component: _c701f1c6,
    name: "kit-id-all"
  }, {
    path: "/lista/*",
    component: _a02829c2,
    name: "lista-all"
  }, {
    path: "/informacoes-gerais/*",
    component: _df078c34,
    name: "informacoes-gerais-all"
  }, {
    path: "/",
    component: _6bce249e,
    name: "index"
  }],

  parseQuery: function(query) {
			const qs = require("qs")

			return qs.parse(query)
		},
  stringifyQuery: function(query) {
			const qs = require("qs")

			const result = qs.stringify(query)

			return result ? `?${result}` : ""
		},
  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
